import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import Mariage from "../images/Mariage.jpg"
import Event from "../images/Event.jpg"
import Hotel from "../images/Hotel.jpg"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const images = [
  {
    url: Hotel,
    id: "serviceAdvertising",
    title: "ADVERTISING",
    to: "/gallery#galleryAdvertising",
    description: [
      "Do you want to boost the profile of your business with a video?",
      "I’m here to create short films and provide photo shots for your website, channels or social media accounts. A video is a modern and contemporary way to catch the attention and trust of your future customers. Whether your business is a hotel, a restaurant, a bar, a distillery, a sporting pursuit or anything else you have in mind, I’m ready to discuss it with you, get inspired and achieve a wonderful result. Therefore, promoting your enterprise through a combination of the appropriate music, special effects and intuitive editing in order to attract prospective customers. I film in harmony with the surrounding since the simplest detail can make the difference, always with respect for the environment.",
    ],
  },
  {
    url: Mariage,
    id: "serviceWedding",
    title: "WEDDING",
    to: "/gallery#galleryWedding",
    description: [
      "Is your wedding coming soon?",
      "I’m here to capture your special day and make it last forever through my films. Marriage is about a unique, an eternal devotion, about the dreams come true. The challenge for me is to bring out the emotions of such a Dream Day. I love meeting Mr&Mrs to be and together we will discuss all the details. I am always willing to hear your wonderful ideas in order to create the perfect video and meet your expectations. Either your wedding is in the city, the countryside, or on an island, I am ready to capture the beauty of the ceremony along with the landscape. Nothing better to start your new life than with the memories of this special day in a video that will accompany you for a lifetime.",
    ],
  },
  {
    url: Event,
    id: "serviceEvent",
    title: "EVENT",
    to: "/gallery#galleryEvent",
    description: [
      "Do you plan an event you want to memorize?",
      "It can be a christening, a birthday party, a surprise party, a wedding anniversary, a graduation, a wedding proposal – literally anything you can think of - I am more than happy to be part of your special occasion creating the perfect video. All the details that make an event memorable – the place, the decoration, the food, but above all, the happy smiling people, are some of the elements that I am going to include in the video. Together we will discuss all the details, I am always willing to hear your ideas. Either your event takes place indoors, in the countryside, or on the beach, I am ready to capture its beauty along with the landscape. Your occasion will last forever through a unique and personalized film.",
    ],
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  image: {
    position: "relative",
    height: "62vh",
    cursor: "inherit",
    [theme.breakpoints.down("sm")]: {
      height: "75vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "90vh",
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    right: "85px",
    bottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
    cursor: "pointer",
    "&:hover, &$focusVisible": {
      "& $imageTitle": {
        border: "2px solid currentColor",
        right: "-2px",
        bottom: "-2px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      left: "0",
      right: "0",
      "& $imageTitle": {
        border: "2px solid currentColor",
        right: "-2px",
        bottom: "-2px",
      },
    },
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.25,
    transition: theme.transitions.create("opacity"),
  },
  imageLeftTitle: {
    zIndex: "1000",
    color: "white",
    position: "absolute",
    top: "calc(31vh - 1rem)",
    letterSpacing: "0.1rem",
    [theme.breakpoints.down("sm")]: {
      top: "2.5rem",
      left: "75px !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: `${theme.spacing(3)}px`,
      top: "1.50rem",
      left: "0 !important",
      right: 0,
    },
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px`,
  },
  description: {
    position: "absolute",
    zIndex: "1000",
    marginLeft: "350px",
    right: "85px",
    maxWidth: "1000px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "75px",
    },
  },
  string: {
    color: "white",
    textAlign: "justify",
  },
}))
const ServicesPage = () => {
  const classes = useStyles()

  if (typeof window !== "undefined") {
    return (
      <Layout>
        <SEO title="services" />
        <div className={classes.root}>
          {images.map(image => (
            <ButtonBase
              id={image.id}
              key={image.title}
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
              disableRipple
              style={{
                width: "100%",
              }}
            >
              <span
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                  onClick={() => {
                    navigate(image.to)
                  }}
                >
                  GO TO GALLERY
                </Typography>
              </span>
              <Typography
                variant="h5"
                component="h2"
                className={classes.imageLeftTitle}
                style={{
                  left:
                    image.id === "serviceEvent"
                      ? "130px"
                      : image.id === "serviceWedding"
                      ? "110px"
                      : "75px",
                }}
              >
                {image.title}
              </Typography>
              <div className={classes.description}>
                {image.description.map((string, index) => {
                  return (
                    <Fragment key={index}>
                      <Typography
                        variant={index === 0 ? "body1" : "body2"}
                        className={classes.string}
                      >
                        {string}
                      </Typography>
                    </Fragment>
                  )
                })}
              </div>
            </ButtonBase>
          ))}
        </div>
      </Layout>
    )
  } else return <></>
}

export default ServicesPage
